import { render, staticRenderFns } from "./Highlights.vue?vue&type=template&id=2ac4cd40&scoped=true&"
import script from "./Highlights.vue?vue&type=script&lang=js&"
export * from "./Highlights.vue?vue&type=script&lang=js&"
import style0 from "./Highlights.vue?vue&type=style&index=0&id=2ac4cd40&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac4cd40",
  null
  
)

export default component.exports