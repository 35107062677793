<template>
  <div>
    <v-carousel
      v-if="images.length > 1"
      delimiter-icon="mdi-minus"
      :hide-delimiter-background="images.length < 2"
      :hide-delimiters="images.length < 2"
      :show-arrows="images.length > 1"
    >
      <v-carousel-item
        v-for="(imageSrc,i) in images"
        :key="i"
        :src="imageSrc"
        width="600"
        reverse-transition="fade-transition"
        transition="fade-transition"
        contain
      ></v-carousel-item>
    </v-carousel>
    <img
      v-else
      lazy-src="@/assets/product-image-placeholder.png"
      style="width: 100%; max-width: 600px;"
      @error="$event.target.src=require(`@/assets/product-image-placeholder.png`)"
      :src="images[0] ?? null"
      contain>
  </div>
</template>

<script>
  export default {
    props: ['images']
  }
</script>